const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://localhost:5001/",
        media_url: "https://canthoip-api.cgis.asia/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3001",
        domainName: "",
        workSpace: "canthoip",
        wmsBaseLink: "https://geo.cgis.asia/geoserver/canthoip/wms/",
  },
  production: {
      api: "https://canthoip-api.cgis.asia/",
      media_url: "https://canthoip-api.cgis.asia/",
      domainAdminSide: "https://admin.canthoip.cgis.asia",
      domainUserSide: "https://canthoip.cgis.asia",
      domainName: "canthoip.cgis.asia",
      workSpace: "canthoip",
      wmsBaseLink: "https://geo.cgis.asia/geoserver/canthoip/wms/",
  },
};

module.exports = apiEnvironment[env];
