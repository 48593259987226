/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApiUrl } from '../../../api/api-url';
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

//--- Action
import * as newsAction from "../../../redux/store/news/news.store";
import * as ptAction from "../../../redux/store/planning-type/planning-type.store"
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import dateformat from "dateformat";
//--- Material Control
import {
    DialogActions,
    TextareaAutosize,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    makeStyles,
    Typography,
    IconButton,
    Select,
    MenuItem,
    Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import FileInputComponent from "../../../components/file-input/file-input.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
    NotificationMessageType,
    APIUrlDefault,
    MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as appActions from "../../../core/app.store";
import FileManagement from "../../../components/file_management/file_management";
//--- Styles
import "../news.scss";


const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

function AddNews(props) {
    const classes = useStyles();
    const [event, setEvent] = useState([]);
    const {
        showLoading,
        isOpen,
        onClose,
        onSuccess,
        newsId,
        GetListAll,
    } = props;
    const [currentindex, setIndex] = useState(0);
    const [newsModel, setNewsModel] = useState();
    const [status, setStatus] = useState(true);
    const [isHot, setIsHot] = useState(true);
    const [imageUrl, setImageUrl] = useState("");
    const [imageFile, setImageFile] = useState();
    const [content, setContent] = useState();
    const [isDelete, setIsDelete] = useState(false);
    const [isDeleteImage, setIsDeleteImage] = useState(false);
    
    const [isShowImageEvent, setShowImageEvent] = useState(false);
    const [documentSelected, setDocumentSelected] = useState([]);
    const [isDocumentShow, setDocumentShow] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentsTemp, setDocumentsTemp] = useState([]);
  
    const [gallerySelected, setGallerySelected] = useState([]);
    const [isGalleryShow, setGalleryShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [galleryTemp, setGalleryTemp] = useState([]);
    const [isShow, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [eventFile, setEventFile] = useState([]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShowGalary, setShowGalary] = useState(false);
    const [filesGalary, setFilesGalary] = useState([]);
    const [filesTempGalary, setFilesTempGalary] = useState([]);
    const urlUploadImage = APIUrlDefault + ApiUrl.UrlUploadFromEditor;
    const [data, setData] = useState({});
    const [imgUrl, setImgUrl] = useState("");
    const [video1, setVideo1] = useState("");
    const [video2, setVideo2] = useState("");
    const [video3, setVideo3] = useState("");
    const [galaryModel, setGalaryModel] = useState([]);
    const onOpenSelectDocument = () => {
        setDocumentShow(true);
        setDocumentsTemp(documents);
    };
    const onCloseSelectDocument = () => {
        setDocumentShow(false);
        setDocuments(documentsTemp);
    };
    const onSaveSelectDocument = () => {
        setDocumentShow(false);
        setDocumentSelected([...documentSelected, ...documents])
    };
    const onOpenSelectGallery = () => {
        setGalleryShow(true);
        setGalleryTemp(gallery);
    };
    const onCloseSelectGallery = () => {
        setGalleryShow(false);
        setGallery(galleryTemp);
    };
    const onSaveSelectGallery = () => {
        setGalleryShow(false);
        setGallerySelected([...gallerySelected, ...gallery])
    };
    useEffect(() => {
        GetDetailPlanningType(newsId);
    }, []);
    const GetDetailPlanningType = (id) => {
        // res.content.documentsFiles && setDocumentSelected(res.content.documentsFiles) ;
        // res.content.imageFiles && setGallerySelected(res.content.imageFiles);          
        setImgUrl("")
        setFiles([]);
        setGalaryModel([])
        setVideo1(null)
        setVideo1(null)
        setVideo1(null)
        setEvent([]);
    }

    const { register, handleSubmit, setError, errors, clearErrors } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const addGalary = (file) => {
        if (file) {
            galaryModel[galaryModel.length] = file;

        }
    }

    const onSubmit = (data_) => {
        console.log(data_)
        if (!data_) {
            return;
        }
        let formData = new FormData();
        formData.append("id", 0);
        formData.append("name", data_.name);
        data_.title && formData.append("title", data_.name);
        data_.description && formData.append("description", data_.description);
        formData.append("content", content);
        video1 && formData.append("video1", video1);
        video2 && formData.append("video2", video2);
        video3 && formData.append("video3", video3);
        if (files && files.length > 0)
            formData.append("documentUploadId", files[0].fileId);
        documentSelected.length > 0 && documentSelected.map(item => {
            formData.append('documents', item.fileId)
          })
        gallerySelected.length > 0 && gallerySelected.map(item => {
            formData.append('images', item.fileId)
          })
        formData.append("TotalArea", data_.totalArea);
        formData.append("AvailableRentArea", data_.availableRentArea);
        formData.append("FillRate", data_.fillRate);
        formData.append("PowerSystem", data_.powerSystem);
        formData.append("WastewaterTreatmentSystem", data_.wastewaterTreatmentSystem);
        formData.append("WaterSystem", data_.waterSystem);
        console.log(event)
        if(event && event.length > 0)
        {
            for (let i = 0; i < event.length; i++) {
                formData.append(`event[${i}][date]`, event[i].date);
                formData.append(`event[${i}][description]`, event[i].description);
                if (event[i].avatar!=null) {
                  formData.append(`event[${i}][avatar][fileId]`, event[i].avatar.fileId);
                  formData.append(`event[${i}][avatar][fileName]`, event[i].avatar.fileName);
                  formData.append(`event[${i}][avatar][filePath]`, event[i].avatar.filePath);
                }
              }
        }
        ptAction
            .CreatePlanningType(formData)
            .then(() => {
                GetListAll();
                ShowNotification(
                    viVN.Success.NewsEditSuccess,
                    NotificationMessageType.Success
                );
                onSuccess();
            }).catch((err) => {
                onSuccess();
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            });
    };

    const handleChangeContent = (editorContent) => {
        clearErrors(["editorContent"]);
        if (editorContent === "<p><br></p>") {
            setError("editorContent", { type: "required" });
            setContent("");
        } else {
            clearErrors("editorContent");
            setContent(editorContent);
        }
    };



    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
    };

    const onSaveEventFile = () => {
        setShowImageEvent(false);
        console.log('onSaveEventFile',eventFile)
        const newData = [...event];
        newData[currentindex].avatar = {fileId: eventFile[0].fileId, fileName: eventFile[0].fileName, filePath: eventFile[0].filePreview};
        setEvent(newData);
        console.log(event)
    };
    const onCloseEventFile = () => {
        setShowImageEvent(false);
        setEventFile(eventFile);
    };

    const onSaveSelectFile = () => {
        console.log(files);
        setShow(false);
    };

    const onOpenSelectFileGalary = () => {
        setShowGalary(true);
        setFilesTempGalary(filesGalary);
    };

    const onCloseSelectFileGalary = () => {
        setShow(false);
        setFiles(filesTempGalary);
    };

    const onSaveSelectFileGalary = () => {
        console.log(filesGalary);
        setShowGalary(false);
        addGalary(filesGalary[0])
    };
    const handleAddRow = () => {
        setEvent([...event, { date: '', description: '', avatar: null }]);
    };
    const handleDeleteRow = (index) => {
        let newData = [...event];
        newData.splice(index, 1);
        setEvent(newData);
    };
    const handleText1Change = (e, index) => {
        let newData = [...event];
        newData[index].date = e.target.value;
        setEvent(newData);
    };

    const handleText2Change = (e, index) => {
        const newData = [...event];
        newData[index].description = e.target.value;
        setEvent(newData);
    };

    const onOpenSelectFileEvent = (event, index) => {
        console.log('setShowImageEvent(true)')
        setIndex(index);
        setShowImageEvent(true);
    };
    return (
        <div>
            <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="lg">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Thêm mới KCX&CN</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {data && (
                        <DialogContent className="pt-4 pb-2">
                            <div className="form-group">
                                <label className="text-dark">
                                    Tên khu CN<span className="required"></span>
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    type="text"
                                    placeholder="Tên khu công nghiệp"
                                    defaultValue={data.name}
                                    ref={register({ required: true, maxLength: 500 })}
                                />
                                {errors.name && errors.name.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.name && errors.name.type === "maxLength" && (
                                    <span className="error">Trường này không quá 150 ký tự</span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="text-dark">Tóm tắt<span className="required"></span></label>
                                <textarea
                                    name="description"
                                    rows="5"
                                    ref={register({ required: true, maxLength: 500 })}
                                    className={
                                        "form-control" +
                                        (errors.description && errors.description.type === "required"
                                            ? " is-invalid"
                                            : "")
                                    }
                                    maxLength="500"
                                    defaultValue={data.description}
                                ></textarea>
                                {errors.description && errors.description.type === "required" && (
                                    <span className="error">Trường này là bắt buộc</span>
                                )}
                                {errors.description && errors.description.type === "maxLength" && (
                                    <span className="error">Trường này không quá 500 ký tự</span>
                                )}
                            </div>
                            <div
                                className="row form-group"
                                style={{ marginLeft: 0 }}>
                                <div
                                    className="col-4 col-md-4 col-lg-4"
                                    style={{ paddingLeft: 0 }}
                                >
                                    <label className="text-dark">
                                        Tổng diện tích
                                    </label>
                                    <input
                                        id="totalArea"
                                        name="totalArea"
                                        className="form-control"
                                        type="number"
                                        placeholder="Tổng diện tích"
                                        defaultValue={data.totalArea}
                                        ref={register}
                                    />
                                </div>
                                <div
                                    className="col-4 col-md-4 col-lg-4"
                                >
                                    <label className="text-dark">
                                        Diện tích có sẵn cho thuê
                                    </label>
                                    <input
                                        id="availableRentArea"
                                        name="availableRentArea"
                                        style={{ width: "50%!important" }}
                                        className="form-control"
                                        type="number"
                                        placeholder="Tổng diện tích"
                                        defaultValue={data.availableRentArea}
                                        ref={register}
                                    />
                                </div>
                                <div
                                    className="col-4 col-md-4 col-lg-4"
                                >
                                    <label className="text-dark">
                                        Tỉ lệ lấp đầy
                                    </label>
                                    <input
                                        id="fillRate"
                                        name="fillRate"
                                        className="form-control"
                                        type="number"
                                        placeholder="Tổng diện tích"
                                        defaultValue={data.fillRate}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div
                                className="row form-group"
                                style={{ marginLeft: 0 }}>
                                <div
                                    className="col-4 col-md-4 col-lg-4"
                                    style={{ paddingLeft: 0 }}
                                >
                                    <label className="text-dark">
                                        Hệ thống điện
                                    </label>
                                    <input
                                        id="powerSystem"
                                        name="powerSystem"
                                        className="form-control"
                                        type="text"
                                        placeholder="Hệ thống điện"
                                        defaultValue={data.powerSystem}
                                        ref={register}
                                    />
                                </div>
                                <div
                                    className="col-4 col-md-4 col-lg-4"
                                >
                                    <label className="text-dark">
                                        Hệ thống cấp nước
                                    </label>
                                    <input
                                        id="waterSystem"
                                        name="waterSystem"
                                        style={{ width: "50%!important" }}
                                        className="form-control"
                                        type="text"
                                        placeholder="Hệ thống cấp nước"
                                        defaultValue={data.waterSystem}
                                        ref={register}
                                    />
                                </div>
                                <div
                                    className="col-4 col-md-4 col-lg-4"
                                >
                                    <label className="text-dark">
                                        Hệ thống xử lý nước thải
                                    </label>
                                    <input
                                        type="text"
                                        id="wastewaterTreatmentSystem"
                                        name="wastewaterTreatmentSystem"
                                        className="form-control"
                                        placeholder="Hệ thống xử lý nước thải"
                                        defaultValue={data.wastewaterTreatmentSystem}
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="text-dark">
                                    Danh sách sự kiện<span className="required"></span>
                                </label>
                                <Table
                                    className="dx-datagrid-table dx-datagrid-table-fixed dx-select-checkboxes-hidden"
                                    style={{ tableLayout: "fixed" }}
                                >
                                    <TableBody>
                                        {event.map((row, index) => (
                                            <tr key={index}>
                                                {/* column 1 */}
                                                <td style={{ padding: 0 }} class="col-1">
                                                    <input 
                                                        className="form-control" 
                                                        placeholder="Ngày: dd/yyyy" 
                                                        type="text" 
                                                        value={row.date} 
                                                        onChange={(event) => handleText1Change(event, index)} />
                                                </td>
                                                {/* column 2 */}
                                                <td 
                                                    style={{ padding: 2 }} class="col-5">
                                                        <input 
                                                            className="form-control" 
                                                            placeholder="Tóm tắt nội dung" 
                                                            type="text" 
                                                            value={row.description} 
                                                            onChange={(event) => handleText2Change(event, index)} 
                                                            />
                                                </td>
                                                {/* column 3 */}
                                                <td  class="col-2">
                                                        {(row.avatar != null)?
                                                        (
                                                            <div key={row.avatar.fileName} style={{ width: "150px" }}>
                                                                <a
                                                                    onClick={(event) => onOpenSelectFileEvent(event, index)}
                                                                    src={APIUrlDefault + row.avatar.filePath}
                                                                    alt={row.avatar.fileName}
                                                                    className="img-fluid mb-2"
                                                                    style={{
                                                                        width: "auto",
                                                                        height: "auto",
                                                                        maxWidth: "100%",
                                                                        maxHeight: "100%",
                                                                    }}
                                                                > {row.avatar.fileName}</a>
                                                            </div>
                                                        )
                                                        :
                                                        (<Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(event) => onOpenSelectFileEvent(event, index)}
                                                    //onClick={(event) => handleFileChange(event, index)}
                                                    >
                                                        <AddAPhotoIcon />
                                                    </Button>)
                                                        }
       
                                                        <TextField
                                                            inputRef={register({ required: true })}
                                                            type="hidden"
                                                            name="image"
                                                            value={
                                                                (files && files.length > 0 && files[0].fileName) || ""
                                                            }
                                                        />
                                                </td>
                                                <td class="col-1">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleDeleteRow(index)}
                                                    >
                                                        -
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </TableBody>
                                    <tfoot>
                                        <div className="row" style={{ marginLeft: 0, marginTop: 5 }}>
                                            <Button variant="contained"
                                                color="primary"
                                                onClick={handleAddRow}>
                                                Thêm sự kiện
                                            </Button>
                                        </div>
                                    </tfoot>
                                </Table>
                            </div>

                            <div className="form-group">
                                <label className="text-dark">Nội dung</label>
                                <SunEditor
                                    enableToolbar={true}
                                    showToolbar={true}
                                    setContents={data.content}
                                    videoFileInput={false}
                                    setOptions={{
                                        height: 500,
                                        imageUploadUrl: urlUploadImage,
                                        imageUploadSizeLimit: MaxSizeImageUpload,
                                        imageAccept: '.jpg,.jpeg,.png,.gift,.svg,.tif',
                                        buttonList: [
                                            [
                                                "undo",
                                                "redo",
                                                "font",
                                                "fontSize",
                                                "formatBlock",
                                                "paragraphStyle",
                                                "blockquote",
                                                "bold",
                                                "underline",
                                                "italic",
                                                "strike",
                                                "subscript",
                                                "superscript",
                                                "fontColor",
                                                "hiliteColor",
                                                "textStyle",
                                                "removeFormat",
                                                "outdent",
                                                "indent",
                                                "align",
                                                "horizontalRule",
                                                "list",
                                                "lineHeight",
                                                "table",
                                                "link",
                                                "image",
                                                "video",
                                                "audio",
                                                "fullScreen",
                                                "showBlocks",
                                                "codeView",
                                            ],
                                        ],
                                    }}
                                    onChange={handleChangeContent}
                                    onBlur={(event, editorContents) => handleChangeContent(editorContents)}
                                />
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <div className="row">
                                        <label className="text-dark ml-3">
                                            Link video youtube
                                        </label>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="video1"
                                                defaultValue={data.video1}
                                                onChange={(e) =>
                                                    setVideo1(e.target.value)
                                                }
                                            />

                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="video2"
                                                defaultValue={data.video2}
                                                onChange={(e) =>
                                                    setVideo2(e.target.value)
                                                }
                                            />

                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="video3"
                                                defaultValue={data.video3}
                                                onChange={(e) =>
                                                    setVideo3(e.target.value)
                                                }
                                            />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <label className="text-dark">
                                        Ảnh<span className="required"></span>
                                    </label>
                                    {!isShow &&
                                        files &&
                                        files.length > 0 &&
                                        files.map((item) => (
                                            <div key={item.fileName} style={{ width: "150px" }}>
                                                <img
                                                    src={APIUrlDefault + item.filePreview}
                                                    alt={item.fileName}
                                                    className="img-fluid mb-2"
                                                    style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        maxWidth: "100%",
                                                        maxHeight: "100%",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={onOpenSelectFile}
                                        >
                                            Chọn file
                                        </Button>
                                        <TextField
                                            inputRef={register({ required: true })}
                                            type="hidden"
                                            name="image"
                                            value={
                                                (files && files.length > 0 && files[0].fileName) || ""
                                            }
                                        />
                                        {errors.image && errors.image.type === "required" && (
                                            <p className="error">Trường này là bắt buộc</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    <label className="text-dark">Thư viện ảnh</label> </div>
                                <div className="col-12">
                                    <div className="list__img d-flex flex-wrap mt-1">
                                        {!isGalleryShow &&
                                            gallerySelected &&
                                            gallerySelected.length > 0 &&
                                            gallerySelected.map((item) => (
                                                <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                                    <img
                                                        src={APIUrlDefault + item.filePreview}
                                                        alt={item.fileName}
                                                        title={item.fileName}
                                                        className="img-fluid mb-2"
                                                        style={{
                                                            width: "auto",
                                                            height: "auto",
                                                            maxWidth: "100%",
                                                            maxHeight: "100%",
                                                        }}
                                                    />
                                                    <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0, 15) + '...') : item.fileName}</p>
                                                    <p
                                                        className="close_x"
                                                        onClick={() =>
                                                            setGallerySelected(gallerySelected.filter(i => i !== item))
                                                        }
                                                    >
                                                        &#10005;
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={onOpenSelectGallery}
                                        >
                                            Chọn file
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                <div className="col-12">
                  <label className="text-dark">File đính kèm</label> </div>
                <div className="col-12">
                  <div className="list__img d-flex flex-wrap mt-1">
                    {!isDocumentShow &&
                      documentSelected &&
                      documentSelected.length > 0 &&
                      documentSelected.map((item) => (
                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                          <img
                            src={require("../../../assets/icon/default.svg")}
                            alt={item.fileName}
                            title={item.fileName}
                            className="img-fluid mb-2"
                            style={{
                              width: "auto",
                              height: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                          <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0,15)+'...') : item.fileName}</p>
                          <p
                            className="close_x"
                            onClick={() =>
                              setDocumentSelected(documentSelected.filter(i => i !== item))
                            }
                          >
                            &#10005;
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onOpenSelectDocument}
                    >
                      Chọn file
                    </Button>
                  </div>
                </div>
              </div>
                        </DialogContent>
                    )}

                    <DialogActions className="border-top">
                        <Button
                            type="button"
                            onClick={onClose}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                        >
                            Lưu
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {isDocumentShow && (
                <Dialog
                    onClose={onCloseSelectDocument}
                    open={isDocumentShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectDocument}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={documents}
                            fileSelected={documentSelected}
                            setFiles={setDocuments}
                            acceptedFiles={["doc", "docx", "txt", "pdf", "png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectDocument}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {documents && documents.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectDocument}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {isGalleryShow && (
                <Dialog
                    onClose={onCloseSelectGallery}
                    open={isGalleryShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectGallery}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={gallery}
                            setFiles={setGallery}
                            acceptedFiles={["png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectGallery}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {gallery && gallery.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectGallery}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
                        {
                isShowImageEvent && (
                    <Dialog
                        onClose={onCloseSelectFile}
                        open={isShowImageEvent}
                        fullWidth={true}
                        maxWidth="md"
                        className="dialog-preview-form"
                    >
                        <DialogTitle disableTypography>
                            <Typography variant="h6">Quản lý file</Typography>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={onCloseSelectFile}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <FileManagement
                                files={eventFile}
                                setFiles={setEventFile}
                                acceptedFiles={["jpeg", "png", "jpg"]}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="button"
                                onClick={onCloseEventFile}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            {eventFile && eventFile.length > 0 && (
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={onSaveEventFile}
                                >
                                    Lưu
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                isShow && (
                    <Dialog
                        onClose={onCloseSelectFile}
                        open={isShow}
                        fullWidth={true}
                        maxWidth="md"
                        className="dialog-preview-form"
                    >
                        <DialogTitle disableTypography>
                            <Typography variant="h6">Quản lý file</Typography>
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={onCloseSelectFile}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            <FileManagement
                                files={files}
                                setFiles={setFiles}
                                acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="button"
                                onClick={onCloseSelectFile}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            {files && files.length > 0 && (
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    onClick={onSaveSelectFile}
                                >
                                    Lưu
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>
                )
            }
        </div>
    );
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(AddNews);
